<template>
  <div>
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div class="content">
        <h3 style="color:red;">周榜：</h3>
        <p>第一名：奖励46星星</p>
        <p>第二名：奖励36星星</p>
        <p>第三名：奖励26星星</p>
        <p>第四~十名：奖励16星星</p>
        <p>第十~二十名：奖励6星星</p>
        <h3 style="color:red;">月榜：</h3>
        <p>第一名：奖励106星星</p>
        <p>第二名：奖励76星星</p>
        <p>第三名：奖励46星星</p>
        <p>第四~十名：奖励26星星</p>
    </div>
    
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
export default{
    components: {
    vHeader
  },
  data(){
    return{
        title: this.$route.meta.title,
    }
    }
}
</script>

<style lang="less" scoped>
.content{
    margin-left:1rem;
    p{
        font-size: 0.3rem;
    }
}
</style>